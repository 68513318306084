// TOOLS
// @import 'tools/mixins';
// @import 'tools/fonts';
// @import 'tools/variables';
// @import 'tools/reset';

$owl-image-path: "/node_modules/owl.carousel/src/img/";

@import 'config/config';

@import 'config/modularized-normalize-scss/normalize.scss'; //Normalize
@import 'config/sass-mq/mq.scss'; //MQ
@import 'config/bootstrap/bootstrap'; //Bootstrap
@import 'config/fullpage/fullpage'; //fullpage
@import 'config/owl_carousel/owl.carousel.min'; //fullpage
@import 'config/animatecss/animate.min'; //fullpage

/* # Utilities */

@import 'elements/containers';
@import 'elements/buttons';
@import 'config/functions';
@import 'config/mixins';

// tools 
@import 'tools/variables';
@import 'tools/fonts';

/* # Fonts */

@import "config/fonts";


//base
@import 'base/fonts-mixins';
@import 'base/base';
@import 'base/animate';
@import 'base/loader';
@import 'base/text';

//atom
@import 'atom/anchors-submenu';
@import 'atom/aside-menu';
@import 'atom/buttons';
@import 'atom/links';
@import 'atom/masked-text';
@import 'atom/section-pricing';
@import 'atom/type-chirurgie';

//molecule
@import 'molecule/card';
@import 'molecule/form';

// organism
@import 'organism/wrapper-buttons';
@import 'organism/card-list';
@import 'organism/nav';
@import 'organism/row-info';
@import 'organism/team-member';
@import 'organism/template-slider';
@import 'organism/cabinet';
@import 'organism/breadcrumbs';
@import 'organism/keypoint';
@import 'organism/flowerlist';
@import 'organism/treatments';
@import 'organism/price-table';
@import 'organism/submenu';
@import 'organism/subheader';
@import 'organism/pdf-list';
@import 'organism/details';
// LIBS
// @import 'libs/locomotive';
// @import 'libs/mq';

// Template
@import 'template/header';
@import 'template/footer';
@import 'template/layout';
@import 'template/subheader';

@import 'pages/templatev2';
@import 'pages/cryolipolyse';
@import 'pages/hydrafacial';
@import 'pages/laser';
@import 'pages/espace-soins';
@import 'pages/visage';
@import 'pages/drainage';
// ELEMENTS
// @import 'elements/containers';
// @import 'elements/buttons';
// @import 'elements/headings';


// PAGES
@import 'pages/index';
